<template>
  <div class="tabs-inventory">
    <Tabs
      v-model="editableTabsValue"
      type="card"
      editable
      @edit="handleTabsEdit"
    >
      <TabPane
        v-for="(item, index) in editableTabs"
        :key="item.id"
        :label="item.title"
        :name="item.id"
      >
        <span
          slot="label"
          @click="handleTabsClick(item, index)"
          @dblclick="handleTabDoubleClick({ id: item.id, title: item.title })"
        >
          <span>{{ item.title }}</span>
        </span>
        <slot></slot>
      </TabPane>
    </Tabs>
  </div>
</template>
<script>
let parentCurrentIndex = 0;
import { Tabs, TabPane } from "element-ui";
export default {
  name: "SideNav",
  components: {
    Tabs,
    TabPane,
  },
  props: {
    editableTabs: [],
    startStep: Number,
    level: Number,
  },
  data() {
    return {
      editableTabsValue: "1",
      tabIndex: 1,
    };
  },

  methods: {
    // tab被选中
    handleTabsClick(data, index) {
      if (this.level == 1) {
        parentCurrentIndex = index;
      }
      this.$emit("handleCurrentTabSelected", {
        currentSelectTabInfo: data,
        status: 2,
        parentCurrentIndex: parentCurrentIndex,
      });
    },
    // 双击tab显示drawer组件修改label
    handleTabDoubleClick(data) {
      this.$emit("handleCurrentTabSelected", {
        currentSelectTabInfo: data,
        status: 3,
      });
    },
    // 修改label
    changeTitle(data) {
      this.$emit("handleCurrentTabSelected", {
        currentSelectTabInfo: data,
        status: 3,
      });
      this.$refs.drawerForm.handleClose();
    },
    handleTabsEdit(targetName, action) {
      if (action === "add") {
        let newTabName = "n" + Number(++this.tabIndex + this.startStep);
        let addObj = {
          id: newTabName,
          title: "New Tab",
          name: newTabName,
          level: this.level,
        };
        // this.editableTabs.push(addObj);
        this.$emit("handleCurrentTabSelected", {
          currentSelectTabInfo: addObj,
          status: 1,
          parentCurrentIndex: parentCurrentIndex,
        });
      }
      if (action === "remove") {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
        // this.$emit("handleCurrentTabSelected", {
        //     currentSelectTabInfo: data,
        //     status: 3,
        // });
      }
    },
  },
};
</script>
  <style lang="scss">
.el-tabs__item {
  user-select: none;
}
.demo-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
}

.demo-drawer__content form {
  flex: 1;
}

.demo-drawer__footer {
  display: flex;
}

.demo-drawer__footer button {
  flex: 1;
}
.tabs-inventory {
  margin-bottom: 10px;
}
.el-tabs__header {
  margin: 0 0 20px;
}
</style>
  