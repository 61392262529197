<template>
  <div>
    <Drawer
      title="修改Tab"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <Form :model="form">
          <FormItem label="标签名" :label-width="formLabelWidth">
            <Input v-model="form.title" autocomplete="off"></Input>
          </FormItem>
        </Form>
        <div class="demo-drawer__footer">
          <Button @click="handleClose">取 消</Button>
          <Button type="primary" @click="sureDrawer" :loading="loading">{{
            loading ? "提交中 ..." : "确 定"
          }}</Button>
        </div>
      </div>
    </Drawer>
  </div>
</template>
<script>
import { Input, Drawer, Form, FormItem, Button } from "element-ui";
export default {
  name: "DrawerForm",
  components: {
    Input,
    Drawer,
    Form,
    FormItem,
    Button,
  },
  props: {
    title: String,
    data: {},
  },
  data() {
    return {
      dialog: false,
      loading: true,
      form: {
        title: "",
      },
      formLabelWidth: "80px",
    };
  },

  methods: {
    showForm(data) {
      this.dialog = true;
      this.loading = false;
      this.form = { ...data };
    },
    handleClose() {
      this.dialog = false;
      this.loading = false;
      this.form = {
        title: "",
      };
    },

    sureDrawer() {
      this.loading = true;
      this.dialog = false;
      console.log(this.form);
      this.$emit("changeTitle", this.form);
    },
  },
};
</script>