<template>
  <div>
    <TabsInsventory
      @handleCurrentTabSelected="handleCurrentTabSelected"
      :startStep="1"
      :editableTabs="[...newTabs]"
      :level="1"
    >
      <TabsInsventory
        @handleCurrentTabSelected="handleCurrentTabSelected"
        :startStep="100"
        :editableTabs="[...newsChild]"
        :level="2"
      >
        <TabsInsventory
          @handleCurrentTabSelected="handleCurrentTabSelected"
          :startStep="1000"
          :editableTabs="[...newsChildSub]"
          :level="3"
        >
        </TabsInsventory>
      </TabsInsventory>
    </TabsInsventory>
    <DrawerForm
      ref="drawerForm"
      :title="DrawerFormTitle"
      @changeTitle="changeTitle"
    ></DrawerForm>
  </div>
</template>
<script>
import DrawerForm from "./components/DrawerForm.vue";
import TabsInsventory from "./components/TabsInventory.vue";
export default {
  name: "SideNav",
  components: { TabsInsventory, DrawerForm },
  data() {
    return {
      DrawerFormTitle: "修改标题",
      newTabs: [],
      newsChild: [],
      newsChildSub: [],
      editableTabs: [
        {
          id: "N12921057450614653",
          title: "Tab 1",
          name: "N12921057450614653",
          level: 1,
          children: [
            {
              pid: "N12921057450614653",
              id: "N12921057450614652",
              title: "Tab 1",
              name: "N12921057450614652",
              level: 2,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.newTabs = [...this.editableTabs];
    this.newsChild = [...this.newTabs[0].children];
  },

  methods: {
    handleCurrentTabSelected(params) {
      let self = this;
      let { currentSelectTabInfo, status, parentCurrentIndex } = params;
      switch (status) {
        case 1: // 新增
          if (currentSelectTabInfo.level == 1) {
            this.newTabs.push(currentSelectTabInfo);
          } else {
            if (
              typeof this.newTabs[parentCurrentIndex]["children"] == "undefined"
            ) {
              this.newTabs[parentCurrentIndex]["children"] = [];
            }
            this.newTabs[parentCurrentIndex]["children"].push(
              currentSelectTabInfo
            );
            this.newsChild = [...this.newTabs[parentCurrentIndex].children];
          }
          break;
        case 3: // 显示drawerForm
          self.DrawerFormTitle = "修改标签";
          self.$refs.drawerForm.showForm(currentSelectTabInfo);
          break;
        default:
          if (currentSelectTabInfo.level == 1) {
            if (
              typeof this.newTabs[parentCurrentIndex]["children"] == "undefined"
            ) {
              this.newTabs[parentCurrentIndex]["children"] = [];
            }
            this.newsChild = [...this.newTabs[parentCurrentIndex].children];
          }
          break;
      }
    },
    handleClickAdd() {
      this.DrawerFormTitle = "新增标签";
      this.$refs.drawerForm.showForm();
    },
    changeTitle() {},
    // todo 修改label
    changeLabel(currentSelectTabInfo, newsData = []) {
      this.newTabs.forEach((item) => {
        if (item.id == currentSelectTabInfo.id) {
          item.title = currentSelectTabInfo.title;
          newsData = item;
        } else if (item.children && item.children.length > 0) {
          this.changeLabel(currentSelectTabInfo, newsData);
        }
      });
    },
  },
};
</script>
<style lang="scss">
</style>
